import { format, getWeekOfMonth, parseISO } from 'date-fns'

const getOrdinalNum = (n) => n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '')

const formatDate = (date, type = 'datetime', customFormat = '') => {
  if (date) {
    if (type == 'slashed-date') {
      return format(new Date(parseISO(date)), 'MM/dd/yyyy')
    }

    if (type == 'datetime') {
      return format(new Date(parseISO(date)), 'MMM dd, yyyy hh:mm a')
    }

    if (type == 'date') {
      return format(new Date(parseISO(date)), 'MMM dd, yyyy')
    }

    if (type == 'monthweek') {
      return format(new Date(parseISO(date)), 'MMM, yyyy') + ' (' + getOrdinalNum(getWeekOfMonth(new Date(parseISO(date)), { weekStartsOn: 2 })) + ' week)'
    }

    if (type == 'monthyear') {
      return format(new Date(parseISO(date)), 'MMM, yyyy')
    }

    if (type == 'format') {
      return format(new Date(parseISO(date)), customFormat)
    }

    if (type == 'time') {
      return format(new Date(parseISO(date)), 'hh:mm a')
    }
  } else {
    return '-'
  }
}

export default formatDate